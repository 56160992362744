export enum reportedTypeColumns {'新 建' = 0,'新建' = 1,'已付款'= 2, '已完成'= 3,'已退款'= 4, '进行中'= 5,'付款中'= 6,'已作废'= 9 }
export enum orderTypeColumns { '线上', '面部','线下' }
export enum orderStatusColumns {'新 建' = 0,'新建' = 1,'已付款'= 2, '已完成'= 3,'已退款'= 4, '进行中'= 5,'付款中'= 6,'已作废'= 9 }
export enum advertisementTypeEnum {'无','官网','医生端登录页','医生端首页'}
export enum safetyTypeEnum {
    'DentalCheck上传' = 1,
    'DentalCheck下载' = 2,
    'DentalShape上传' = 3,
    'DentalShape下载' = 4,
    'DentalDesign上传' = 5,
    'DentalDesign下载' = 6,
    'DentalWork上传' = 7,
    'DentalWork下载' = 8,
    'DentalMonitor上传' = 9,
    'DentalMonitor下载' = 10,
}