import request from '@/utils/request'

// 获取订单
export const searchInstance: any = (data: any) => {
    return request({
        url: `/oral-detection/oralDetectionList`,
        method: 'post',
        data
    })
}
// 根据检测订单id获取面部照图片路径
export const getOrderInfo:any = (id:any)=>{
    return request({
        url: `/oral-detection/info/${id}`,
        method: 'get',
    })
}


// 获取口腔诊断订单
export const searchOralDiagnosis: any = (data: any) => {
    return request({
        url: `/oralDiagnosis/list`,
        method: 'post',
        data
    })
}

export const downLoadStl: any = (data: any) => {
    return request({
        url: `/oralDiagnosis/downloadStl`,
        method: 'post',
        data,
        responseType: 'blob'// 表明返回服务器返回的数据类型
    })
}

export const downloadCbct: any = (data: any) => {
    return request({
        url: `/oralDiagnosis/downloadCbct`,
        method: 'post',
        data,
        responseType: 'blob'// 表明返回服务器返回的数据类型
    })
}

// 获取正畸设计订单列表
export const searchDesignList: any = (data: any) => {
    return request({
        url: `/orthodontic/design/list`,
        method: 'post',
        data
    })
}
// 获取正畸追踪订单列表
export const searchTrackList: any = (data: any) => {
    return request({
        url: `/orthodontic/track/list`,
        method: 'post',
        data
    })
}
// 获取正畸追踪订单列表
export const searchTrackInfo: any = (id: any) => {
    return request({
        url: `/orthodontic/track/scan/info/${id}`,
        method: 'get',
    })
}
// 获取销售订单列表
export const searchSaleOrder: any = (data: any) => {
    return request({
        url: `/saleOrder/search`,
        method: 'post',
        data
    })
}
// 获取预约订单列表
export const searchAppointment: any = (data: any) => {
    return request({
        url: `/appointment/search`,
        method: 'post',
        data
    })
}


export const getPickImage: any = (id: any) => {
    return request({
        url: `/oral-detection-picked/pickImage/${id}`,
        method: 'get',
    })
}
export const getOralDetectioOss: any = (data: any) => {
    return request({
        url: `/oral-detection-oss/path`,
        method: 'post',
        data
    })
}
export const replaceImage: any = (data: any) => {
    return request({
        url: `/oral-detection-picked/replace`,
        method: 'put',
        data
    })
}

// 根据面部照订单id获取面部照图片路径
export const getFaceImage:any = (id:number) => {
    return request({
        url: `/oral-detection-oss/faceImage/${id}`,
        method: 'get',
    })
}

//获取移动端订单列表
export const mobileOrders:any = (data: any) => {
    return request({
        url: `mobile/orders`,
        method: 'post',
        data,
    })
}

